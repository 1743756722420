import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Text, Box, Badge } from "../../components/Core";

import styled from "styled-components";

import titleImage from "../../assets/image/jpeg/japan.jpg";
import iconQuote from "../../assets/image/png/quote-icon.png";
import styles from '../../assets/styles/blog.module.css';

const Post = styled(Box)`
  overflow: hidden;
  font-size: 1rem;

  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  ul,
  ol {
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.25rem;
    margin-bottom: 1.25rem;
    color: ${({ theme }) => theme.colors.heading} !important;
  }
  ol li {
    list-style-type: decimal;
  }

  ul li {
    list-style-type: disc;
  }

  blockquote {
    margin-bottom: 1.25rem;
    padding-left: 50px;
    position: relative;
    color: ${({ theme }) => theme.colors.text} !important;
    font-size: 20px;
    &::after {
      content: url(${iconQuote});
      display: inline-block;
      min-width: 28px;
      max-width: 28px;
      margin-top: 8px;
      margin-right: 23px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  img,
  iframe,
  video {
    max-width: 100%;
    margin-bottom: 2rem;
    display: block;
  }
`;

const BadgePost = ({ children }) => (
  <Badge
    mr={3}
    mb={3}
    bg="#eae9f2"
    color="#696871"
    fontSize="16px"
    px={3}
    py={2}
  >
    {children}
  </Badge>
);


const BlogDetails = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="12">
                <Title variant="hero">
                  Sending Australian products to Japan
                  <br className="d-none d-lg-block" />
                </Title>
                <Box className="d-flex justify-content-center">
                  <Text mr={3}>Jan 16, 2020
                  </Text>
                  <Text>Michael Tippett
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="pb-0 pt-4">
          <Container>
            <Row>
              <Col lg="12" className="mb-5">
                {/* <!-- Blog section --> */}
                <Post>
                  <div>
                    <img src={titleImage} alt="Shopping From Japan" className={styles.titleImage} />
                  </div>
                  <div>

                    <b>Direct delivery from Australia to Japan made simple!</b>
                    <p>Are you tired of getting damaged packages despite paying hundreds of dollars in delivery? This has finally come to an end with the introduction of our service!</p>
                    <p>With a simple one-step process, you can now shop from your favorite Australian merchants! All you have to do is provide the merchant with the address given to you by us, and we take care of the mail forwarding!</p>
                    <p>Whether you are an international businessman wanting to buy in bulk or you just want to get accessories for your exotic pets, we have you covered.</p>
                    <p>Once the product arrives at our warehouse, our dedicated logistics team makes sure to properly consolidate its packaging, and make sure that your package gets to you speedily and securely with the use of direct mail all without burning a hole in your pocket.</p>

                    <a href="https://members.hotsnail.com.au/signup">Sign up to HotSnail now</a>
                  </div>
                </Post>
                <Box className="d-flex" mt={4}>
                  <BadgePost>Japan</BadgePost>
                  <BadgePost>Shopping</BadgePost>
                  <BadgePost>Mail forwarding</BadgePost>

                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default BlogDetails;
